<template>
    <div class="basic_data_edit disease_investigation_record">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">病害调查记录信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="遗产组成" prop="ycysbm">
                    <el-select
                        v-model="formdata.saveJson.yczc"
                        placeholder="请选择遗产组成"
                        :disabled="!isEdit"
                        filterable
                        clearable
                        @change="changeYczc()"
                    >
                        <el-option
                            v-for="(item, index) in yczcList"
                            :key="index"
                            :label="item.YCYSFL_2"
                            :value="item.YCYSFL_2"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素" prop="ycysbm">
                    <el-select
                        v-model="formdata.saveJson.ycysbm"
                        placeholder="请选择遗产要素"
                        :disabled="!isEdit"
                        filterable
                        clearable
                        @change="changeYcys()"
                    >
                        <el-option
                            v-for="(item, index) in ycysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="病害类型" prop="bhlx">
                    <div class="flex-y-center">
                        <el-select
                            v-model="formdata.saveJson.bhlx"
                            placeholder="请选择病害类型"
                            filterable
                            clearable
                            :disabled="!isEdit"
                            @change="changeBhlx"
                            style="width: calc(100% - 50px)"
                        >
                            <el-option
                                v-for="(item, index) in bhlxList"
                                :key="index"
                                :label="item.NAME"
                                :value="item.CODE"
                            ></el-option>
                        </el-select>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="构件包括瓦片、梁柱、围板等民居组成部分"
                            placement="top"
                        >
                            <img
                                src="@image/working_desk/monitor_data/sh_not_pass_detail_active.png"
                                style="margin-left: 10px"
                            />
                        </el-tooltip>
                    </div>

                    <!--  -->
                </el-form-item>
                <el-form-item label="病害存在状态" prop="bhczzt">
                    <el-select
                        v-model="formdata.saveJson.bhczzt"
                        placeholder="请选择病害存在状态"
                        filterable
                        clearable
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in bhczztList"
                            :key="index"
                            :label="item.NAME"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="病害编号" prop="bhbh">
                    <!-- 南京的病害编号不能自动生成，需要手动录入 -->
                    <!-- <el-tooltip effect="light"
                                content="自动生成，遗产要素-病害类型-001"
                                placement="bottom"
                    > -->
                    <el-input
                        v-model="formdata.saveJson.bhbh"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        placeholder="请输入病害编号"
                        :disabled="!isEdit"
                    ></el-input>
                    <!-- </el-tooltip> -->
                </el-form-item>
                <el-form-item label="病害名称" prop="bhmc">
                    <el-input
                        v-model="formdata.saveJson.bhmc"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        placeholder="请输入病害名称"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="病害位置" class="from-item-block">
                    <el-tooltip
                        effect="light"
                        content="病害发生的对象、位置、范围描述"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.bhwz"
                            :maxlength="500"
                            onkeydown="if(event.keyCode==32) return false"
                            show-word-limit
                            type="textarea"
                            :autosize="{ minRows: 6 }"
                            :disabled="!isEdit"
                            placeholder="请输入病害位置"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item
                    label="病害经纬度"
                    class="select_jwd"
                    prop="bhjwd"
                >
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="请填写正确的病害经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者点击“坐标拾取”按钮，直接获取经纬度坐标"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.bhjwd"
                            onkeydown="if(event.keyCode==32) return false"
                            placeholder="请输入病害经纬度"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                    <el-button size="small" @click="showPickup = true"
                        >坐标拾取</el-button
                    >
                </el-form-item>
                <br />
                <el-form-item label="图片/测绘图/立面图">
                    <el-cascader
                        v-model="formdata.saveJson.bhlmtid"
                        placeholder="请选择图片/测绘图/立面图"
                        :options="chtlmtList"
                        filterable
                        clearable
                        :props="bhlmtProps"
                        :disabled="!isEdit"
                        @change="getLMTPic"
                        @visible-change="changeListShow"
                    ></el-cascader>
                </el-form-item>
                <el-form-item v-if="isEdit" label="上传图片">
                    <el-upload
                        class="upload-demo"
                        accept=".gif,.jpg,.jpeg,.bmp,.png"
                        :action="`${BASE_URL}UpLoad/BHDCJLFileSave?LJ=bhdcjlZp&YCYSBM=${formdata.saveJson.ycysbm}`"
                        :show-file-list="false"
                        :on-success="handleSuccess"
                        :before-upload="beforeUpload"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传gif/jpg/jpeg/bmp/png文件
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="病害立面分布位置" class="from-item-block">
                    <mapImgDraw
                        class="map-img-draw"
                        :imgUrl.sync="formdata.saveJson.bhlmtlj"
                        :drawConf.sync="drawConf"
                        :jsonlist.sync="formdata.saveJson.bhlmfbwz"
                        :showPopup="true"
                        @update:jsonlist="getJsonList"
                        @clickLayer="clickLayer"
                    >
                    </mapImgDraw>
                </el-form-item>
                <el-form-item label="备注" prop="bz" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.bz"
                        :maxlength="500"
                        type="textarea"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        placeholder="请输入备注"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="病害示意图" prop="bhsyt">
                    <!-- 照片上传一张和上传多张使用了一样的组件 -->
                    <el-upload
                        :action="`${BASE_URL}UpLoad/FileSave`"
                        list-type="picture-card"
                        accept="image/png,image/jpg,image/jpeg,image/gif"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :limit="1"
                        :class="{ noneupload: fileList.length === 1 }"
                        :on-success="handleAvatarSuccess"
                        :disabled="!isEdit"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />`
                    </el-dialog>
                </el-form-item>
            </el-form>
            <coordinatePickup
                :editState="isEdit"
                :showPickupP.sync="showPickup"
                :coordinate.sync="formdata.saveJson.bhjwd"
            ></coordinatePickup>
        </div>
        <el-dialog
            class="deepBlueDialog"
            title="请输入位置描述"
            :visible.sync="dialogFormVisible"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <el-form
                :model="form"
                label-width="80px"
                class="demo-form-inline dialog-form-inline"
            >
                <el-form-item label="位置描述">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item class="footer">
                    <sn-button
                        type="primary"
                        :snButton.sync="nameButton"
                        @handleChange="handleDialogChange"
                    ></sn-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import coordinatePickup from "@comp/map/coordinate_pickup";
import mapImgDraw from "@comp/map/mapDraw/MapImgDraw";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "disease_investigation_record",
    components: {
        coordinatePickup,
        mapImgDraw,
    },
    mixins: [infoMixin],
    data() {
        let checkCoordinate = (rule, value, callback) => {
            let reg2 = /^([0-9.]+,)[0-9.]+$/;
            if (value === "" || !value) {
                callback(new Error("请输入病害经纬度"));
            } else if (!reg2.test(value)) {
                callback(new Error("请输入正确格式的病害经纬度"));
            } else {
                callback();
            }
        };
        return {
            BASE_URL: window.REQUEST_URL,
            notNeedFile: true,
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            showPickup: false,
            snButton: snButton,
            rules: {
                ycysbm: [
                    {
                        required: true,
                        message: "请选择遗产要素",
                        trigger: "change",
                    },
                ],
                bhlx: [
                    {
                        required: true,
                        message: "请选择病害类型",
                        trigger: "change",
                    },
                ],
                bhczzt: [
                    {
                        required: true,
                        message: "请选择病害存在状态",
                        trigger: "change",
                    },
                ],
                bhmc: [
                    {
                        required: true,
                        message: "请输入病害名称",
                        trigger: "blur",
                    },
                ],
                bhbh: [
                    {
                        required: true,
                        message: "请输入病害编号",
                        trigger: "blur",
                    },
                ],
                bhjwd: [
                    {
                        required: true,
                        validator: checkCoordinate,
                        trigger: "change",
                    },
                ],
                bhsyt: [
                    {
                        required: true,
                        message: "请上传病害示意图",
                        trigger: "change",
                    },
                ],
            },
            // 病害存在状态
            bhczztList: [
                {
                    NAME: "尚存在",
                    CODE: "0",
                },
                {
                    NAME: "已修复",
                    CODE: "1",
                },
            ],
            ycysList: [], //遗产要素
            yczcList: [], //遗产组成
            bhlxList: [],
            chtlmtList: [],
            bhlmtProps: {
                value: "id",
                label: "name",
            },
            drawConf: {
                show: true,
                draw: {
                    polygon: false,
                    polyline: false,
                },
            },
            dialogFormVisible: false,
            form: {
                name: "",
            },
            jsonList: [],
            markerIsClick: true,
            nameButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    shzt: 1,
                    ycysbm: "",
                    bhlx: "",
                    bhczzt: "",
                    bhmc: "",
                    bhwz: "", // 病害位置
                    bhbh: "",
                    bhjwd: "",
                    bhjd: "",
                    bhwd: "",
                    bhsyt: "",
                    bhlmtlj: "", // 病害图片
                    bhlmtid: "",
                    bhlmfbwz: "", // 标绘 json
                    bz: "", // 备注
                    bhlmtmc: "",
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "12",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    watch: {
        "formdata.saveJson.bhsyt": function (val) {
            this.getPicList();
        },
        "formdata.saveJson.ycysbm": function (val) {
            // this.getBhlxList();
            this.getChtList();
        },
        "formdata.saveJson.bhlmfbwz": function (val) {
            this.jsonList = JSON.parse(val);
        },
    },
    mounted() {
        this.getYczcList();
        this.getYcys();
        this.getBhlxList();
        this.drawConf.show = this.isEdit;
    },
    methods: {
        ...mapActions([
            "GetYcysListByYczc",
            "getbhlxList",
            "createBHBH",
            "GetBhlmtList",
            "GetYczcListByYcys",
        ]),

        changeBhlx() {
            // if (this.formdata.saveJson.ycysbm !== '' && this.formdata.saveJson.bhlx !== '' && this.isEdit) {
            //     this.createBhbh();
            // }
        },
        getPicList() {
            let pic = this.formdata.saveJson.bhsyt;
            if (pic) {
                this.fileList = [{ url: pic }];
            }
        },
        // 获取遗产组成列表
        async getYczcList() {
            let result = await this.GetYczcListByYcys({});
            this.yczcList = result.RESULTVALUE;
        },
        // 改变遗产组成
        changeYczc() {
            this.getYcys();
        },
        async getYcys() {
            this.formdata.saveJson.ycysbm = "";
            this.formdata.saveJson.bhjwd = "";
            let res = await this.GetYcysListByYczc({
                yczc: this.formdata.saveJson.yczc
                    ? this.formdata.saveJson.yczc
                    : "",
            });
            this.ycysList = res.RESULTVALUE;
        },
        // 改变遗产要素 清空病害类型
        changeYcys() {
            // 选择遗产要素 默认选中 病害经纬度
            if (this.formdata.saveJson.ycysbm) {
                let obj = this.common.aryListFindOne(
                    this.ycysList,
                    "BM",
                    this.formdata.saveJson.ycysbm
                );
                this.formdata.saveJson.bhjwd = `${obj.JD},${obj.WD}`;
            }
            this.formdata.saveJson.bhlmtlj = "";
            this.formdata.saveJson.bhlmfbwz = "";
            this.formdata.saveJson.bhlmtmc = "";
            this.formdata.saveJson.bhlmtid = "";
        },
        async getBhlxList() {
            // let bm = this.formdata.saveJson.ycysbm;
            // if (!bm) return false;
            let res = await this.getbhlxList({});
            this.bhlxList = res.ResultValue;
        },
        async createBhbh() {
            let ycysbm = this.formdata.saveJson.ycysbm;
            let bhid = this.formdata.saveJson.bhid;
            let bhlx = this.formdata.saveJson.bhlx;
            let oldBhbh = this.formdata.saveJson.bhbh;
            let res = await this.createBHBH({
                ycysbm: ycysbm,
                bhid: bhid,
                bhlx: bhlx,
                oldBhbh: oldBhbh,
            });
            if (res.ResultValue) {
                this.formdata.saveJson.bhbh = res.ResultValue;
            }
        },
        async getChtList() {
            let ycysbm = this.formdata.saveJson.ycysbm;
            let res = await this.GetBhlmtList({
                ycysbm: ycysbm,
            });
            this.chtlmtList = res.ResultValue || [];
        },
        getLMTPic(ids) {
            this.formdata.saveJson.bhlmfbwz = "";
            if (ids.length === 0) {
                this.formdata.saveJson.bhlmtlj = "";
                this.formdata.saveJson.bhlmtmc = "";
                this.formdata.saveJson.bhlmtid = "";
                return false;
            }
            let val = this.chtlmtList
                .filter((i) => {
                    return i.id === ids[0];
                })[0]
                .children.filter((j) => {
                    return j.id === ids[1];
                })[0];
            this.formdata.saveJson.bhlmtid = val.id;
            this.formdata.saveJson.bhlmtlj = val.lj;
            this.formdata.saveJson.bhlmfbwz = "";
            this.formdata.saveJson.bhlmtmc = val.name;
        },
        changeListShow(val) {
            val && this.getChtList();
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleAvatarSuccess(res, file) {
            let path = res.ResultValue[0].FilePath;
            this.formdata.saveJson.bhsyt = path;
            this.formdata.saveJson.bhsytmc = file.name;
            this.fileList.push(file);
        },
        successUpload(response, file, fileList) {
            this.percentage = 0;
            let newFile = {};
            if (response.IsSuccess) {
                let result = response.ResultValue[0];
                newFile.name = result.FileName;
                newFile.path = result.FilePath;
                newFile.id = result.Guid;
                this.metaData.push(newFile);
            }
        },
        errorUpload(error, file, fileList) {
            this.percentage = 0;
            console.log("上传失败");
            this.$message({
                message: "上传失败",
                type: "error",
            });
        },
        progressUpload(e, file, fileList) {
            this.percentage = Math.floor(file.percentage);
            console.log("上传中", e, file, fileList);
        },
        changeFile(index) {
            this.currentFileIndex = index;
        },
        async deleteFile(fileID, index) {
            let arg = {
                fjid: fileID,
            };
            let result = await this.deleteBasicFile(arg);
            if (result.IsSuccess) {
                this.metaData.splice(index, 1);
            }
        },
        downloadFile(url) {
            window.open(url, "_blank");
        },
        getJsonList(json, event) {
            this.jsonList = JSON.parse(json);
            if (event.type === "draw:created") {
                this.dialogFormVisible = true;
                this.form.name = "";
            } else if (event.type === "draw:deletestart") {
                this.markerIsClick = false;
            } else if (event.type === "draw:deletestop") {
                this.markerIsClick = true;
            }
        },
        clickLayer(layer) {
            if (this.markerIsClick && this.isEdit) {
                this.dialogFormVisible = true;
                this.form.name = layer.feature.properties.name;
            }
        },
        handleDialogChange(type) {
            this[type]();
        },
        buttonSave() {
            if (!this.form.name) {
                this.$message({
                    tpye: "warning",
                    message: "请输入位置说明",
                });
                return;
            } else {
                this.jsonList[this.jsonList.length - 1].properties.name =
                    this.form.name;
                let jsonStr = JSON.stringify(this.jsonList);
                this.formdata.saveJson.bhlmfbwz = jsonStr;
                this.dialogFormVisible = false;
            }
        },
        handleSuccess(response) {
            if (response.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "上传成功，请在图片/测绘图/立面图中进行选择",
                });
            }
        },
        beforeUpload() {
            if (!this.formdata.saveJson.ycysbm) {
                this.$message({
                    type: "warning",
                    message: "请先选择遗产要素再进行上传操作",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.map-img-draw {
    height: 400px;
}
.footer {
    text-align: right;
}
</style>
